html , body, #root {
  height: 100%;
  background-color: #f0f0f0;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  min-height: 70%;
}

.footer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  background-color: #262626;
  color: #fff;
}

.container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 36px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    margin-left: 24px;
    margin-right: 24px;
}

.header {
    margin-bottom: 12px;
}

.link {
    display: block;
    color: #fff;
    text-decoration: none;
    margin-top: 4px;
}

.support-link {
    display: block;
    color: #39E991;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 4px;
}

.app-version {
    position: fixed;
    right: 16px;
    bottom: 0px;
}

.pac-container {
  z-index: 99999999;
}